<script setup lang="ts">
import type { Schemas } from "#shopware";
import { getTranslatedProperty } from "@shopware/helpers";

const props = defineProps<{
  product: Schemas["Product"];
}>();

const { product } = toRefs(props);

const { unitPrice } = useProductPrice(product);

const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const productPath = computed(() => formatLink(`/detail/${props.product.id}`));

const { removeFromCompareList } = useProductCompareList(product);
</script>

<template>
  <div
    class="bg-scheppach-shades-0 flex p-sm gap-sm rd b b-scheppach-neutral-200 text-left max-w-100 w-full"
  >
    <NuxtLink :to="productPath">
      <SharedMediaImage
        :media="product.cover?.media"
        :alt="getTranslatedProperty(product, 'name')"
        class="size-20 min-w-20 object-contain"
      />
    </NuxtLink>
    <NuxtLink :to="productPath">
      <div class="flex flex-col gap-4 min-w-0 flex-grow">
        {{ getTranslatedProperty(product, "name") }}
        <div class="flex flex-grow items-center gap-5.5 justify-between">
          <div
            class="price flex-grow flex items-center gap-sm flex-wrap h-7.5 overflow-x-visible overflow-y-hidden"
          >
            <SharedPrice
              :value="unitPrice"
              class="text-5 font-700 c-scheppach-primary-500"
            >
              <template #afterPrice>*</template>
            </SharedPrice>
          </div>
        </div>
      </div>
    </NuxtLink>

    <button
      type="button"
      class="size-5 i-sl-bin-1 text-scheppach-primary-700"
      @click="removeFromCompareList"
    >
      {{ $t("checkout.items.removeButton") }}
    </button>
  </div>
</template>
